<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        A sample container is found to hold
        <number-value :value="atoms" unit="\text{atoms}" />
        of
        <chemical-latex :content="atomName.toLowerCase()" />, how many <b>moles</b> of the element
        do you have?
      </p>

      <calculation-input
        v-model="inputs.mol"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) What is the mass of the sample described in part a)?</p>

      <calculation-input
        v-model="inputs.mass"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question377',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        mol: null,
        mass: null,
      },
    };
  },
  computed: {
    atoms() {
      return this.taskState.getValueBySymbol('atoms').content;
    },
    chemicalElement() {
      return this.taskState.getValueBySymbol('element').content;
    },
    atomName() {
      return this.chemicalElement.name;
    },
  },
};
</script>
